<template>
  <div class="eh-web-app-header-top">
    <b-container fluid>
      <b-row align-v="center" align-h="between">
        <b-col cols="12" sm="5" class="py-2">
          <label class="eh-web-app-header-upper-site-title">
            {{ $store.state.headerPageTitle.pageTitle }}
          </label>
        </b-col>
        <b-col v-if="hasLangSwitch" cols="12" sm="3">
          <fp-header-lang-switch />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

export default {
  name: "TitleLine",
  props: {
    hasLangSwitch: Boolean,
  },
};
</script>
